var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('div',{staticClass:"toolbar d-flex align-center toolbarStyle wsRoundedLight",style:(("position : fixed; top : " + _vm.top + "px; left : " + _vm.left + "px;"))},[_c('ft-select',{attrs:{"disabled":_vm.isList,"value":_vm.selectedFormat,"items":_vm.fontStyles,"delay-close":""},on:{"input":_vm.formatBlock,"expand":function($event){return _vm.$emit('expand' , $event);}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var text = ref.text;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"d-flex align-center px-2 wsRoundedLight",staticStyle:{"transition":"0.2s ease background-color"},style:(_vm.isList ? "pointer-events : none" : null),attrs:{"color":hover ? _vm.wsBACKGROUND :"transparent","min-width":"110","height":"32"},on:{"click":function($event){return _vm.$emit('expand' , true)}}},[_c('h5',{staticClass:"text-no-wrap mb-0",style:(("color  : " + (_vm.isList ? _vm.wsBACKGROUND : _vm.wsACCENT)))},[_vm._v(_vm._s(text || _vm.$t('font.p') + ' 1'))]),_c('v-icon',{attrs:{"color":_vm.isList ? _vm.wsBACKGROUND : _vm.wsACCENT}},[_vm._v("mdi-menu-down")])],1)]}}],null,true)})]}}],null,false,1624765140)}),_vm._l((_vm.toolbarItemsSelect),function(item,i){return _c('div',{key:i,staticClass:"d-flex align-center"},[(item.separator)?_c('v-sheet',[_c('v-divider',{staticClass:"mx-2",staticStyle:{"border-color":"var(--wsBACKGROUND)","height":"30px"},attrs:{"vertical":""}})],1):(item.linkDropdown)?_c('v-menu',{key:i + 'link_dropdown',attrs:{"offset-y":"","close-on-content-click":false},on:{"input":_vm.handleLinkDropdown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"d-flex align-center justify-center pointer wsRoundedLight mr-1",attrs:{"color":hover || _vm.activeMarks.link ? _vm.wsBACKGROUND : null,"height":"32","width":"32"},on:{"click":function($event){return item.action(item.value)}}},'v-sheet',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.wsACCENT}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)})]}}],null,true),model:{value:(_vm.linkDropdown),callback:function ($$v) {_vm.linkDropdown=$$v},expression:"linkDropdown"}},[_c('v-sheet',{staticClass:"pa-3 mt-1 ",staticStyle:{"border-radius":"8px"}},[_c('ws-text-field',{attrs:{"label":_vm.$t('InsertLink'),"width":"300px","error":_vm.urlError,"error-messages":_vm.urlError,"hide-details":false,"placeholder":"https://example.com"},on:{"input":function($event){_vm.urlError = null}},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}}),_c('div',{staticClass:"mt-3 d-flex justify-space-between"},[_c('v-btn',{attrs:{"disabled":!_vm.activeMarks.link,"icon":""},on:{"click":function($event){return _vm.formatMark('link')}}},[_c('v-icon',{attrs:{"color":!_vm.activeMarks.link ? _vm.wsBACKGROUND : _vm.wsACCENT}},[_vm._v(" mdi-link-variant-remove ")])],1),_c('ws-button',{attrs:{"disabled":!_vm.url || _vm.url === _vm.selectedLink,"label":"Apply"},on:{"click":function($event){return _vm.makeLink(_vm.url)}}})],1)],1)],1):(!item.children)?_c('v-hover',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"d-flex align-center justify-center pointer wsRoundedLight mr-1",style:(item.disabled ? "pointer-events : none" : null),attrs:{"color":hover || _vm.activeMarks[item.value] ? _vm.wsBACKGROUND : null,"height":"32","width":"32"},on:{"click":function($event){return item.action(item.value)}}},[_c('v-icon',{attrs:{"color":item.disabled ? _vm.wsBACKGROUND : _vm.wsACCENT}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)}):(item.children)?_c('ft-select',{key:i + 'dropdown',attrs:{"items":item.children,"min-width":"0","delay-close":""},on:{"input":function($event){return item.action($event)},"expand":function($event){return _vm.$emit('expand' , $event);}}},[_c('v-btn',{staticClass:"px-0 mx-0",attrs:{"color":_vm.wsACCENT,"min-width":"30","elevation":"0","width":"30","height":"30","text":""}},[_c('v-icon',{attrs:{"size":"20","color":_vm.wsACCENT}},[_vm._v(_vm._s(item.icon)+" ")])],1)],1):_vm._e()],1)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"d-flex align-center justify-center pointer wsRoundedLight mr-1",attrs:{"color":hover || _vm.activeMarks[_vm.item.value] ? _vm.wsBACKGROUND : null,"height":"32","width":"32"},on:{"click":function($event){return _vm.formatMark(_vm.item.value)}}},[_c('v-icon',{attrs:{"color":_vm.wsACCENT}},[_vm._v(_vm._s(_vm.item.icon))])],1)]}}],null,false,3327490795)}),_c('portal',{attrs:{"to":"prose_mirror_portal"}},[(_vm.displayImageDialog)?_c('ws-dialog',{attrs:{"title":_vm.$t('AddImage'),"width":"400"},on:{"save":_vm.insertImage},model:{value:(_vm.displayImageDialog),callback:function ($$v) {_vm.displayImageDialog=$$v},expression:"displayImageDialog"}},[_c('image-param-picker',{attrs:{"disable-portal":""},on:{"input":_vm.imageUrl},model:{value:(_vm.imageUrl),callback:function ($$v) {_vm.imageUrl=$$v},expression:"imageUrl"}}),_c('a-parameter-slider',{staticClass:"pb-6",attrs:{"default":"100","label":_vm.$t('Width'),"track-color":_vm.wsBACKGROUND,"font-color":_vm.wsDARKER,"value-color":_vm.wsACCENT,"min":"0","max":"100","unit":"%","light":""},model:{value:(_vm.imageWidth),callback:function ($$v) {_vm.imageWidth=$$v},expression:"imageWidth"}})],1):_vm._e()],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }