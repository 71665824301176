import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

export function placeholderPlugin(placeholder) {
    return new Plugin({
        props: {
            decorations(state) {
                const doc = state.doc;
                if (doc.childCount > 1 || doc.textContent.length > 0) {
                    return DecorationSet.empty;
                }

                const placeholderDecoration = Decoration.widget(1, () => {
                    const span = document.createElement("span");
                    span.className = "placeholder";
                    span.textContent = placeholder;
                    return span;
                });

                return DecorationSet.create(doc, [placeholderDecoration]);
            },
        },
    });
}