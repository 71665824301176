<template>
  <div
      v-if="visible"
      :style="`position : fixed; top : ${top}px; left : ${left}px;`"
      class="toolbar d-flex align-center toolbarStyle wsRoundedLight"
  >
    <ft-select
        :disabled="isList"
        :value="selectedFormat"
        :items="fontStyles"
        delay-close
        @input="formatBlock"
        @expand="$emit('expand' , $event);"


    >
      <template #default="{text}">
        <v-hover #default="{hover}">
          <v-sheet
              @click="$emit('expand' , true)"
              class="d-flex align-center px-2 wsRoundedLight"
              :color="hover ? wsBACKGROUND :`transparent`"
              min-width="110"
              height="32"
              v-ripple
              style="transition: 0.2s ease background-color"
              :style="isList ? `pointer-events : none` : null"
          >
            <h5 class="text-no-wrap mb-0" :style="`color  : ${isList ? wsBACKGROUND : wsACCENT}`">{{ text || $t('font.p') + ' 1' }}</h5>
            <v-icon :color="isList ? wsBACKGROUND : wsACCENT">mdi-menu-down</v-icon>
          </v-sheet>
        </v-hover>
      </template>

    </ft-select>

    <div class="d-flex align-center"  v-for="(item,i) in toolbarItemsSelect" :key="i">
      <!-- Divider -->
      <v-sheet v-if="item.separator">
        <v-divider
            class="mx-2"
            vertical
            style="border-color: var(--wsBACKGROUND); height: 30px"
        />
      </v-sheet>


      <!-- Link dropdown-->
      <v-menu
          v-else-if="item.linkDropdown"
          v-model="linkDropdown"
          @input="handleLinkDropdown"
          :key="i + 'link_dropdown'"
          offset-y
          :close-on-content-click="false"
      >
        <template v-slot:activator="{attrs,on}">

          <v-hover #default="{hover}">
            <v-sheet
                v-bind="attrs" v-on="on"
                :color="hover || activeMarks.link ? wsBACKGROUND : null"
                class="d-flex align-center justify-center pointer wsRoundedLight mr-1"
                height="32" width="32"
                @click="item.action(item.value)"
                v-ripple
            >
              <v-icon :color="wsACCENT">{{ item.icon }}</v-icon>
            </v-sheet>
          </v-hover>

        </template>
        <v-sheet style="border-radius: 8px" class="pa-3 mt-1 ">
          <ws-text-field
              v-model="url"
              @input="urlError = null"
              :label="$t('InsertLink')"
              width="300px"
              :error="urlError"
              :error-messages="urlError"
              :hide-details="false"
              placeholder="https://example.com"
          />
          <div class="mt-3 d-flex justify-space-between">
            <v-btn :disabled="!activeMarks.link" @click="formatMark('link')"  icon>
              <v-icon :color="!activeMarks.link ? wsBACKGROUND : wsACCENT " >
                mdi-link-variant-remove
              </v-icon>
            </v-btn>

            <ws-button
                @click="makeLink(url)"
                :disabled="!url || url === selectedLink"
                label="Apply"
            />

          </div>
        </v-sheet>

      </v-menu>
      <!-- Buttons -->
      <v-hover
            v-else-if="!item.children" :key="i"
            #default="{hover}"
        >
          <v-sheet
              :color="hover || activeMarks[item.value] ? wsBACKGROUND : null"
              class="d-flex align-center justify-center pointer wsRoundedLight mr-1"
              height="32" width="32"
              @click="item.action(item.value)"
              v-ripple
              :style="item.disabled ? `pointer-events : none` : null"
          >
            <v-icon :color="item.disabled ? wsBACKGROUND : wsACCENT">{{ item.icon }}</v-icon>
          </v-sheet>
        </v-hover>
      <!-- Regular dropdown-->
      <ft-select
          v-else-if="item.children"
          @input="item.action($event)"
          @expand="$emit('expand' , $event);"
          :key="i + 'dropdown'"
          :items="item.children"
          min-width="0"
          delay-close
      >
        <v-btn
            :color="wsACCENT"
            class="px-0 mx-0"
            min-width="30"
            elevation="0"
            width="30"
            height="30"
            text
        >
          <v-icon size="20" :color="wsACCENT">{{ item.icon }} </v-icon>
        </v-btn>
      </ft-select>



    </div>

    <v-hover

        #default="{hover}"
    >
      <v-sheet
          :color="hover || activeMarks[item.value] ? wsBACKGROUND : null"
          class="d-flex align-center justify-center pointer wsRoundedLight mr-1"
          height="32" width="32"
          @click="formatMark(item.value)"
          v-ripple
      >
        <v-icon :color="wsACCENT">{{ item.icon }}</v-icon>
      </v-sheet>
    </v-hover>

    <!-- Add more buttons as needed -->


    <portal to="prose_mirror_portal">
      <ws-dialog
          v-model="displayImageDialog"
          v-if="displayImageDialog"
          :title="$t('AddImage')"
          @save="insertImage"
          width="400"
      >

        <image-param-picker
            @input="imageUrl"
            v-model="imageUrl"
            disable-portal
        />

        <a-parameter-slider
            v-model="imageWidth"
            default="100"
            :label="$t('Width')"
            :track-color="wsBACKGROUND"
            :font-color="wsDARKER"
            :value-color="wsACCENT"
            class="pb-6"
            min="0"
            max="100"
            unit="%"
            light
        />
      </ws-dialog>
    </portal>


  </div>
</template>

<script>
import {toggleMark , setBlockType  } from "prosemirror-commands";
import { wrapInList , liftListItem ,  } from "prosemirror-schema-list";
import {undo,redo,undoDepth , redoDepth} from "prosemirror-history";
import ImageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker.vue";
import {NodeSelection} from "prosemirror-state";

export default {
  components: {ImageParamPicker},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    top: {
      type: Number,
      required: true
    },
    left: {
      type: Number,
      required: true
    },
    view : {},
    schema : {},
    deselectTrigger : {
      type: Number
    }
  },
  data() {
    return {
      url : null,
      imageUrl : null,
      imageWidth : null,
      urlError : false,
      linkDropdown : false,
      displayImageDialog : false,

      selectedNodeDom : null,
      isImageSelected : false

    }
  },
  computed: {

    // Selects

    toolbarItemsSelect() {
      let alignemntItems = [
        { value : 'left'      , icon : 'mdi-format-align-left'   },
        { value : 'center'    , icon : 'mdi-format-align-center'   },
        { value : 'right'     , icon : 'mdi-format-align-right'   },
        { value : 'justify'   , icon : 'mdi-format-align-justify'   },
      ]
      alignemntItems = alignemntItems.filter(el => el.value !== this.selectedAlignment )



      let items = [
        { value : 'strong'    ,
          icon : 'mdi-format-bold'      ,
          action : this.formatMark
        },
        { value : 'em'        ,
          icon : 'mdi-format-italic'    ,
          action : this.formatMark
        },
        { value : 'underline' ,
          icon : 'mdi-format-underline' ,
          action : this.formatMark
        },

        { value : 'link' ,
          icon : 'mdi-link' ,
          linkDropdown : true,
        },
        { value : 'image' ,
          icon : 'mdi-image' ,
          action : this.openImageDialog
        },
        // { value : "code_block" ,
        //   icon : 'mdi-code-array' ,
        //   action : this.formatBlock
        // },

        { value : 'align' ,
          icon : `mdi-format-align-${this.selectedAlignment || 'left'}` ,
          action : this.setAlignment,
          children : alignemntItems
        },
        { separator : 'true'  },
        { value : 'bullet_list'   ,
          icon : 'mdi-format-list-bulleted' ,
          action : this.makeList,
        },
        { value : 'ordered_list'  ,
          icon : 'mdi-format-list-numbered' ,
          action : this.makeList
        },
        // { value : 'remove_format'  ,
        //   icon : 'mdi-delete-outline' ,
        //   action : this.removeAllMarksCommand
        // },
        { separator : 'true'  },
        { value : 'undo'    ,
          icon : 'mdi-undo'      ,
          action : this.undo,
          disabled : !this.canUndo
        },
        { value : 'redo'    ,
          icon : 'mdi-redo'      ,
          action : this.redo,
          disabled : !this.canRedo
        },
      ]

      return items

    },
    fontStyles() {
      return [
        { text : this.$t('avalon.color_styles.h1') , value : 'heading_1' },
        { text : this.$t('avalon.color_styles.h2') , value : 'heading_2' },
        { text : this.$t('avalon.color_styles.h3') , value : 'heading_3' },
        { text : this.$t('avalon.color_styles.h4') , value : 'heading_4' },
        { text : this.$t('avalon.color_styles.h5') , value : 'heading_5' },
        { text : this.$t('avalon.color_styles.p')  , value : 'paragraph'},
        { text : this.$t('avalon.color_styles.h6') , value : 'heading_6' },
      ]
    },
    // Markings
    activeMarks() {
      const { from, $from, to, empty } = this.view.state.selection;
      const marks = {
        strong: false,
        em: false,
        underline: false,
        link : false,
        bullet_list : this.isList === 'bullet_list',
        ordered_list : this.isList === 'ordered_list',
        image : this.isImageSelected
        // Add other marks as needed
      };

      if (empty) {
        const storedMarks = this.view.state.storedMarks || $from.marks();
        marks.strong = this.schema.marks.strong.isInSet(storedMarks);
        marks.em = this.schema.marks.em.isInSet(storedMarks);
        marks.underline = this.schema.marks.underline.isInSet(storedMarks);
        marks.link = this.schema.marks.link.isInSet(storedMarks);
        // Add checks for other marks
      } else {
        this.view.state.doc.nodesBetween(from, to, node => {
          if (node.isInline) {
            if (this.schema.marks.strong.isInSet(node.marks)) marks.strong = true;
            if (this.schema.marks.em.isInSet(node.marks)) marks.em = true;
            if (this.schema.marks.underline.isInSet(node.marks)) marks.underline = true;
            if (this.schema.marks.link.isInSet(node.marks)) marks.link = true;
            // Add checks for other marks
          }
        });
      }

      return marks;
    },
    selectedLink() {
      const { state } = this.view;
      const { from, to, empty } = state.selection;

      let href = null;

      if (empty) {
        const storedMarks = state.storedMarks || state.selection.$from.marks();
        const linkMark = this.schema.marks.link.isInSet(storedMarks);
        if (linkMark) {
          href = linkMark.attrs.href;
        }
      } else {
        state.doc.nodesBetween(from, to, node => {
          if (node.isInline) {
            const linkMark = this.schema.marks.link.isInSet(node.marks);
            if (linkMark) {
              href = linkMark.attrs.href;
              return false; // Stop iteration once we find the link
            }
          }
        });
      }

      return href;
    },
    selectedAlignment() {
      const { $from } = this.view.state.selection;
      return $from.parent.attrs.align || 'left'
    },
    selectedFormat() {
      const { $from } = this.view.state.selection;
      const type = $from.parent.type
      if (type === this.schema.nodes.paragraph ) {
        return 'paragraph'
      }
      if (type === this.schema.nodes.heading ) {
        return 'heading_' + $from.parent.attrs.level
      }

      return null
    },
    isList() {
      const { $from } = this.view.state.selection;
      for (let d = $from.depth; d > 0; d--) {
        const node = $from.node(d);
        if (node.type.name === "bullet_list") {
          return "bullet_list";
        } else if (node.type.name === "ordered_list") {
          return "ordered_list";
        }
      }
      return null;
    },
    canUndo() {
      return this.view ? undoDepth(this.view.state) > 0 : false;
    },
    canRedo() {
      return this.view ? redoDepth(this.view.state) > 0 : false;
    }

  },
  watch : {
    displayImageDialog() {
      this.$emit('dialog-active' , this.displayImageDialog)
    },
    linkDropdown() {
      this.$emit('dialog-active' , this.linkDropdown)
    },
    visible(value) {
      if (!value) {
        this.linkDropdown = false
      }
    },
    deselectTrigger() {
      if (this.linkDropdown || this.displayImageDialog) {
        return
      }
      this.deselectEditableItems()
    }
  },
  methods: {
    deselectEditableItems() {
      if (this.selectedNodeDom) {
        this.selectedNodeDom.style.opacity = 1
        this.selectedNodeDom.style.outline = 'none'
        this.selectedNodeDom = null

        this.isImageSelected = false
      }
    },
    selectEditableItem(event) {
      event.target.style.opacity = '0.8';
      event.target.style.outline = `3px solid ${this.wsBACKGROUND}`;
      this.selectedNodeDom = event.target
    },
    handleViewClick(event) {
      this.deselectEditableItems()

      if (event.target.tagName.toLowerCase() === 'img') {
        this.selectEditableItem(event)
        this.isImageSelected = true
        return
      }

    },
    getSelectedImageAttributes() {
      const { state } = this.view;
      const { selection, schema } = state;

      // Check if the selection is a NodeSelection and if the node is an image
      if (selection instanceof NodeSelection && selection.node.type === schema.nodes.image) {
        const node = selection.node;
        return {
          src: node.attrs.src,
          width: node.attrs.width,
        };
      }

      return null;
    },

    openImageDialog() {
      this.imageUrl = null
      this.imageWidth = null

      const image = this.getSelectedImageAttributes()
      if (image) {
        this.imageUrl = image.src
        this.imageWidth = parseInt(image.width)
      }

      this.displayImageDialog = true;
    },
    insertImage() {
      const src = this.imageUrl
      const alt = ''
      const title = ''
      const width = `${this.imageWidth || 100}%`

      const {state, dispatch} = this.view;
      const {schema} = state;

      const node = schema.nodes.image.create({src, alt, title, width});
      const transaction = state.tr.replaceSelectionWith(node);
      dispatch(transaction);
      this.displayImageDialog = false;
      this.$emit('change')
    },

    formatMark(type) {
      const { state, dispatch } = this.view;
      toggleMark(this.schema.marks[type])(state, dispatch)
    },
    formatBlock(value) {
      let attrs = {}
      if (value.includes('heading')) {
        const split = value.split('_')
        if (split.length > 1) {
          attrs.level = split[1]
          value = split[0]
        }
      }

      const { state, dispatch } = this.view;
      setBlockType(this.schema.nodes[value], attrs)(state, dispatch)
      this.$emit('change')
    },
    setAlignment(align) {
      const { state, dispatch } = this.view;
      const { $from  } = state.selection;

      // Ensure we're working with a block node
      const node = $from.node($from.depth);
      if (node.isBlock) {
        const attrs = { ...node.attrs, align };
        const tr = state.tr.setNodeMarkup($from.before($from.depth), null, attrs);
        dispatch(tr);
      }
      this.$emit('change')
    },
    makeList(type = 'nothing') {
      if (this.isList) {
        const { state, dispatch } = this.view;
        liftListItem(this.schema.nodes.list_item)(state, dispatch);
        return
      }
      this.formatBlock('paragraph')
      type === 'bullet_list' ? this.makeBulletList() : this.makeOrderedList()
      this.$emit('change')
    },
    async makeBulletList() {
      const { state, dispatch } = this.view;
      const { bullet_list } = state.schema.nodes;
      wrapInList(bullet_list)(state, dispatch);
      this.$emit('change')
    },
    makeOrderedList() {
      const { state, dispatch } = this.view;
      const { ordered_list } = state.schema.nodes;
      wrapInList(ordered_list)(state, dispatch);
      this.$emit('change')
    },
    makeLink(href) {
      if (!href || !this.VALIDATE_URL(href)) {
        this.urlError = 'Введіть правильне посилання в форматі https://westudy.ua'
        this.notify('Введіть правильне посилання в форматі https://westudy.ua' , 'warning')
        return

      }
      const { state, dispatch } = this.view;
      if (!href) return false;
      const attrs = { href };
      if ( this.selectedLink) {

        toggleMark(this.schema.marks.link, attrs)(state, dispatch);
        setTimeout(()=> {
          const { state, dispatch } = this.view;
          toggleMark(this.schema.marks.link, attrs)(state, dispatch);
        },2)
      } else {
        toggleMark(this.schema.marks.link, attrs)(state, dispatch);
      }

      this.linkDropdown = false
      this.$emit('change')

    },

    removeAllMarksCommand() {
      const { state, dispatch } = this.view;
      const { from, to } = state.selection;
      const tr = state.tr;

      state.doc.nodesBetween(from, to, (node, pos) => {
        if (node.isInline) {
          this.schema.marks.forEach(markType => {
            if (markType.isInSet(node.marks)) {
              tr.removeMark(pos, pos + node.nodeSize, markType);
            }
          });
        }
      });

      if (dispatch) {
        dispatch(tr);
      }

      this.$emit('change')

      return true;

    },

    undo() {
      undo(this.view.state, this.view.dispatch);
      this.$emit('change')
    },
    redo() {
      redo(this.view.state, this.view.dispatch);
      this.$emit('change')
    },



    // technical

    handleLinkDropdown(value) {
      if (value ) {
        this.url = this.selectedLink || null
      }
    }


  },
  mounted() {
    setTimeout(()=> {
      this.view.dom.addEventListener('click', this.handleViewClick);
    },300)
  },
  beforeDestroy() {
    this.view.dom.removeEventListener('click', this.handleViewClick);
  }

};
</script>

<style>
.toolbar {
  background: var(--wsWHITE);
  border : 1px solid var(--wsBACKGROUND);
  padding: 4px
}
</style>
