import {schema as basicSchema} from "prosemirror-schema-basic";
import {tableNodes} from "prosemirror-tables";
import {addListNodes, splitListItem} from "prosemirror-schema-list";
import {DOMParser, Schema} from "prosemirror-model";
import {keymap} from "prosemirror-keymap";
import {baseKeymap, chainCommands, liftEmptyBlock, splitBlock} from "prosemirror-commands";
import {EditorState} from "prosemirror-state";
import {placeholderPlugin} from "@/components/UI/tiptap/placeholderPlugin";
import {EditorView} from "prosemirror-view";
import {history, undo , redo} from "prosemirror-history";
// import hljs from "highlight.js";
// import 'highlight.js/styles/default.css';


// Prose Mirror Functions
function makeMarks() {

    const underline = {
        attrs: {},
        parseDOM: [{ tag: "u" }],
        toDOM() { return ["u", 0]; }
    };
    const link = {
        attrs: {
            href: {},
            title: { default: null }
        },
        inclusive: false,
        parseDOM: [
            {
                tag: "a[href]",
                getAttrs(dom) {
                    return {
                        href: dom.getAttribute("href"),
                        title: dom.getAttribute("title")
                    };
                }
            }
        ],
        toDOM(node) {
            let { href, title } = node.attrs;
            return ["a", { href, title }, 0];
        }
    };
    const textColor = {
        attrs: { color: {} },
        parseDOM: [
            {
                style: 'color',
                getAttrs: value => ({ color: value })
            }
        ],
        toDOM: mark => ['span', { style: `color: ${mark.attrs.color};` }, 0]
    };

    // Extend the basic marks with the new underline mark
    return basicSchema.spec.marks.append({
        underline,
        link,
        textColor
    });

}

function makeNodes() {
// Define the image node schema
    const imageNode = {
        inline: true,
        attrs: {
            src: {},
            alt: { default: null },
            title: { default: null },
            width: {  }
        },
        group: "inline",
        draggable: true,
        parseDOM: [{
            tag: "img[src]",
            getAttrs(dom) {
                return {
                    src: dom.getAttribute("src"),
                    alt: dom.getAttribute("alt"),
                    title: dom.getAttribute("title"),
                    width: dom.getAttribute("width") || dom.style.width || null
                };
            }
        }],
        toDOM(node) {
            const { src, alt, title, width } = node.attrs;
            return ["img", { src, alt, title, style: `width: ${width}` }];
        }
    };


    const alignNodes = basicSchema.spec.nodes
        .update("paragraph", {
            ...basicSchema.spec.nodes.get("paragraph"),
            attrs: { ...basicSchema.spec.nodes.get("paragraph").attrs, align: { default: null } },
            parseDOM: [{
                tag: "p",
                getAttrs: dom => {
                const align = dom.style.textAlign || null;
                return { align };
            }
            }],
            toDOM: node => {
                const { align } = node.attrs;
                return ["p", { style: align ? `text-align: ${align};` : null }, 0];
            }
        })
        .update("heading", {
            ...basicSchema.spec.nodes.get("heading"),
            attrs: { ...basicSchema.spec.nodes.get("heading").attrs, align: { default: null } },
            parseDOM: [{
                tag: "h1,h2,h3,h4,h5,h6",
                getAttrs: dom => {
                    const align = dom.style.textAlign || null;
                    return {
                        level: dom.nodeName[1],
                        align
                    };
                }
            }],
            toDOM: node => {
                const { align } = node.attrs;
                return ["h" + node.attrs.level, { style: align ? `text-align: ${align};` : null }, 0];
            }
    });

    const tableNodesDefinition = tableNodes({
        tableGroup: "block",
        cellContent: "block+",
        cellAttributes: {
            background: {
                default: null,
                getFromDOM(dom) { return dom.style.backgroundColor || null; },
                setDOMAttr(value, attrs) { if (value) attrs.style = (attrs.style || "") + `background-color: ${value};`; }
            }
        }
    });

    return addListNodes(alignNodes, "paragraph block*", "block")
            .update("image", imageNode)
            .append(tableNodesDefinition)
        ;

}

// function makeNodeViews() {
//
//     const code_block = (node) => {
//         const dom = document.createElement("pre");
//         dom.className = "hljs";
//         dom.setAttribute("data-language", node.attrs.language);
//         const code = document.createElement("code");
//         code.textContent = node.textContent;
//         dom.appendChild(code);
//         highlightCodeBlock(dom, node);
//         return {
//             dom,
//             contentDOM: code,  // Ensure contentDOM is set for editing
//             update(updatedNode) {
//                 if (updatedNode.type !== node.type) return false;
//                 dom.setAttribute("data-language", updatedNode.attrs.language);
//                 code.className = `language-${updatedNode.attrs.language}`;
//                 code.textContent = updatedNode.textContent;
//                 highlightCodeBlock(dom, updatedNode);
//                 return true;
//             }
//         };
//     }
//
//     return {
//         code_block
//     }
// }
function makeKeymaps(schema) {

    const customKeymap = keymap({
        Enter: chainCommands(
            splitListItem(schema.nodes.list_item),
            liftEmptyBlock,
            splitBlock
        ),
        "Shift-Enter": (state, dispatch) => {
            const { tr } = state;
            if (dispatch) {
                dispatch(tr.replaceSelectionWith(state.schema.nodes.hard_break.create()).scrollIntoView());
            }
            return true;
        },
        "Mod-z": undo,
        "Mod-y": redo,
        "Mod-Shift-z": redo
    });

    return [
        customKeymap ,
        keymap(baseKeymap)
    ];
}

// Technical
function parseHtml(html , schema) {
    const element = document.createElement('div');
    element.innerHTML = html;
    return DOMParser.fromSchema(schema).parse(element);
}

function parseJson(json, schema) {
    return Node.fromJSON(schema, json);
}


// Export Functions
/**
 *
 * @param input
 * @param schema
 * @param toJson
 * @returns {Node|*}
 */
export function makeDoc(input , schema , toJson = false) {

    if (!input) {
        return DOMParser.fromSchema(schema).parse(document.createElement("div"))
    }

    return !toJson ? parseHtml(input , schema) : parseJson(input , schema);
}

/**
 *
 * @returns {Schema<any, any>}
 */
export function makeDefaultSchema() {
    return new Schema({
        nodes: makeNodes(),
        marks: makeMarks()
    });
}

/**
 *
 * @param schema
 * @param input
 * @param toJson
 * @param placeholder
 * @returns {EditorState}
 */
export function makeDefaultState({schema, input = null, toJson = false, placeholder = 'Enter your text'}) {
    return EditorState.create({
        doc: makeDoc(input , schema , toJson),
        plugins: [
            ...makeKeymaps(schema),
            placeholderPlugin(placeholder),
            history()
        ]
    });
}

export function makeDefaultView(dom , state , dispatchTransaction) {
    return new EditorView(dom, {
        state: state,
        dispatchTransaction: dispatchTransaction,
    });
}


